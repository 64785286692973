<template>
    <el-dialog
        :title="$t('employee_details.upload_files')"
        :visible.sync="visible"
        :before-close="closeModal"
        width="600px"
        top="40px"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <div class="px-16 upload-file-container">
            <el-upload
                ref="upload"
                v-loading="$wait.is('uploading files')"
                element-loading-spinner="el-custom-spinner"
                class="w-full"
                action="#"
                :on-change="handleUploadFile"
                :show-file-list="false"
                multiple
                :auto-upload="false"
            >
                <div class="flex justify-center flex-col items-center w-full h-32 border-dashed border-2">
                    <fa-icon :icon="['fad', 'cloud-upload-alt']" style="font-size:50px" class="text-gray-500" />
                    <p class="mt-2">
                        {{ $t('common.click_to_upload_files') }}
                    </p>
                </div>
            </el-upload>
        </div>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="closeModal">{{ $t('common.cancel') }}</el-button>
        </span>
    </el-dialog>
</template>
<script>
import API from './files.api';

export default {
    props: {
        clientId: {
            type:    Number,
            default: null,
        },
        visible: {
            type:    Boolean,
            default: false,
        },
    },

    methods: {
        async handleUploadFile(file) {
            try {
                this.$wait.start('uploading files');

                const formData = new FormData();
                formData.append('file', file.raw);

                await API.upload(this.clientId, formData);

                this.$notify.success({ title: 'Success' });
                this.$emit('refresh');
                this.closeModal();
            } finally {
                this.$wait.end('uploading files');
            }
        },

        closeModal() {
            this.$emit('close');
        },
    },
};
</script>
<style>
    .upload-file-container .el-upload {
        width: 100% !important;
    }
</style>
